import React, { useEffect } from "react";
import Navbar from "../Nav/Navbar";
import Hero from "./Banner.js/Hero";
import ParallaxComponent from "./Features/Features";
import AboutUsComponent from "./About/About";
import { MiniFoot } from "./MiniFooter/Minifoot";
import ProjectList from "../Projects/ProjectList";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

function Home() {
	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		const firstAnimationTrigger = ScrollTrigger.create({
			trigger: ".index-features__tab-head",
			start: "top center",
			end: "center center",
			onEnter: () => {
				gsap.fromTo(
					".index-features__tab-head",
					{ opacity: 0 },
					{ opacity: 1, duration: 1.9, delay: 0.5 }
				);
			},
		});

		// Clean up the ScrollTriggers when the component unmounts
		return () => {
			cleanupScrollTriggers(firstAnimationTrigger);
			// Add cleanup for other ScrollTriggers...
		};
	}, []);

	const cleanupScrollTriggers = (...triggers) => {
		triggers.forEach((trigger) => {
			if (trigger) {
				trigger.kill();
			}
		});
	};

	return (
		<div className="home">
			<main className="main-wrapper">
				<Navbar />
				<div className="fix--bg">
					<Hero />
					<ParallaxComponent />
				</div>
				<ProjectList />
				<AboutUsComponent />
				<MiniFoot />
			</main>
		</div>
	);
}

export default Home;
