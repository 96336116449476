import React from "react";
import Navbar from "../Nav/Navbar";
import "./projectd.css";
import { MiniFoot } from "../MiniFoot.js/MiniFoot";

const ProjectDetail = ({ project }) => {
	if (!project) {
		return <div>Project not found</div>;
	}

	const {
		title,
		overview,
		approach,
		visualStrategy,
		results,
		services,
		videoPath,
		images1,
		images2,
		images0,
		WebDesign,
		images3,
	} = project;

	return (
		<div className="PD">
			<Navbar color="head_color" />

			<div className="projectD">
				<div className="project-section">
					<h2>{title}</h2>
					{services && <p className="pro_p">{services.join(", ")}</p>}
				</div>
				{videoPath && (
					<div className="project-item">
						<video
							src={videoPath}
							muted
							autoPlay
							loop
							playsInline
							style={{ width: "100%" }}
						>
							Your browser does not support the video tag.
						</video>
					</div>
				)}
				{overview && (
					<div className="project-section p-intro">
						<p>{overview}</p>
					</div>
				)}
				{approach && approach.strategy && (
					<div className="project-section">
						<h3>Strategy</h3>
						<p>{approach.strategy}</p>
					</div>
				)}
				{images0 && (
					<div className="project-section_visual_img">
						{images0.map((image, index) => (
							<img
								key={index}
								style={{ margin: "20px 0" }}
								src={image}
								alt={`Img ${index}`}
							/>
						))}
					</div>
				)}
				{approach && approach.branding && (
					<div className="project-section">
						<h3>Branding</h3>
						<p>{approach.branding}</p>
					</div>
				)}
				{images1 && (
					<div className="project-section_img">
						{images1.map((image, index) => (
							<img
								key={index}
								style={{ margin: "20px 0" }}
								src={image}
								alt={`Img ${index}`}
							/>
						))}
					</div>
				)}
				{visualStrategy && (
					<div className="project-section">
						<h3>Visual Strategy</h3>
						<p>{visualStrategy}</p>
					</div>
				)}
				{images2 && (
					<div className="project-section_visual_img">
						{images2.map((image, index) => (
							<img
								key={index}
								style={{ margin: "20px 0" }}
								src={image}
								alt={`Img ${index}`}
							/>
						))}
					</div>
				)}
				{WebDesign && (
					<div className="project-section">
						<h3>Web Design</h3>
						<p>{WebDesign}</p>
					</div>
				)}
				{images3 && (
					<div className="">
						{images3.map((image, index) => (
							<img
								key={index}
								style={{ margin: "20px 0" }}
								src={image}
								alt={`Img ${index}`}
							/>
						))}
					</div>
				)}
				{results && (
					<div className="project-section">
						<h3>Results</h3>
						<p>{results}</p>
					</div>
				)}
			</div>
			<MiniFoot />
		</div>
	);
};

export default ProjectDetail;
