import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap"; // Import gsap
import AOS from "aos";
import "aos/dist/aos.css";
import bubble from "./bubble.svg";
import line from "./line.png";
import web from "./web.png";
const Hero = () => {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	useEffect(() => {
		gsap.fromTo(
			".shape-1",
			{ opacity: 0 },
			{ opacity: 1, duration: 1.5, delay: 0.5 }
		);
	}, []);
	return (
		<>
			<div className="banner  banner-style-5">
				<div className="container">
					<div className="row">
						<div className="">
							<div className="banner-content">
								<h1 data-aos="zoom-in-up" className="title">
									Elevate Your Digital Presence with Wibuld
								</h1>
								<h4
									data-aos="fade-right"
									data-aos-duration="900"
									data-aos-delay="400"
									className="subtitle"
								>
									From Inspiring Web Designs to Branding and Powerful Marketing,
									We Shape Brands for Success
								</h4>
								<div data-aos="zoom-in" data-aos-delay="450">
									<Link
										to={"/project"}
										className="axil-btn btn-fill-white btn-large"
									>
										View Case Studies
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ul className="list-unstyled shape-group-19">
					<li className="shape-container">
						<img className="shape shape-1" src={bubble} alt="Bubble" />
					</li>
					<li className="shape shape-2">
						<img src={line} alt="Bubble" />
					</li>
				</ul>
				<div className="banner_web_cont">
					<img className="banner_web_img" src={web} alt="web" />
				</div>
			</div>
		</>
	);
};

export default Hero;
