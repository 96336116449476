import React from "react";
import Logo from "./Logo.png";
import Nav from "./Nav";
import StickyHeader from "./StickyHeader";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const Navbar = ({ color }) => {
	const sticky = StickyHeader(100);

	const MobileMenuHandler = () => {
		document.querySelector(".mobilemenu-popup").classList.toggle("show");
		document.querySelector("body").classList.toggle("mobilemenu-show");

		var elements = document.querySelectorAll(
			".mobilemenu-popup .menu-item-has-children > a"
		);

		for (var i in elements) {
			if (elements.hasOwnProperty(i)) {
				elements[i].onclick = function () {
					this.parentElement
						.querySelector(".axil-submenu")
						.classList.toggle("active");
					this.classList.toggle("open");
				};
			}
		}
	};

	return (
		<>
			<header className="header axil-header header-style-4">
				<div
					className={`axil-mainmenu ${sticky ? "axil-sticky" : ""} ${
						color && color
					}`}
				>
					<div className="container">
						<div className="header-navbar">
							<div className="header-logo">
								<Link to="/">
									<img src={Logo} alt="logo" width="65px" />{" "}
								</Link>
							</div>
							<div className="header-main-nav">
								<Nav />
							</div>
							<div className="header-action">
								<ul className="list-unstyled">
									<li className="header-btn">
										<Link to={"/contact"} className="axil-btn btn-fill-white">
											Let's Talk
										</Link>
									</li>
									<li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
										<button className="btn-wrap" onClick={MobileMenuHandler}>
											<span></span>
											<span></span>
											<span></span>
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
			<MobileMenu MobileHandler={MobileMenuHandler} />
		</>
	);
};

export default Navbar;
