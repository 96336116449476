import React, { useEffect } from "react";
import "../css/Company.css";
import gsap from "gsap";
import Navbar from "../../Nav/Navbar";
import sample from "./head.mp4";
import one from "./1.jpg";
import j from "./j.jpg";
import sup from "./sup.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { MiniFoot } from "../../MiniFoot.js/MiniFoot";
import { Helmet } from "react-helmet";

const Company = () => {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	useEffect(() => {
		gsap.to(".service_black", {
			backgroundColor: "#101820",
			color: "white",
			scrollTrigger: {
				trigger: ".service_black",
				start: "center center",
				end: "top top", // Change the end position to "top top"
				scrub: 0, // Set scrub to 0 for an instant change
			},
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>About Wibuld - Leading Web Development Agency</title>
				<meta
					name="description"
					content="Learn about Wibuld, a leading web development agency committed to delivering exceptional digital solutions. Discover our team, mission, and values."
				/>
				<meta
					name="keywords"
					content="Wibuld, web development agency, about us, team, mission, values"
				/>
			</Helmet>

			<Navbar />
			<div className="service-page">
				<div className="banner">
					<div className="banner-overlay"></div>
					<video autoPlay loop muted playsInline>
						<source src={sample} type="video/mp4" />
					</video>
					<div className="banner-text">
						<h1 data-aos="zoom-in-up">Fueling Growth with </h1>
						<h1 data-aos="zoom-in-up" data-aos-delay="450">
							Innovative Solutions for Brands
						</h1>
					</div>
				</div>

				<div className="service-content container">
					<div className="text-content company-text">
						<h2 data-aos="zoom-in-up">
							Step into Wibuld, where we transcend the realm of a conventional
							web design agency. We're a collective of fervent innovators who
							understand that brilliance isn't born from a singular idea—it
							thrives through perpetual refinement and a mosaic of perspectives.
						</h2>
					</div>
				</div>
				<div className="service-content container">
					<div className="content-container">
						<div className="text-content text-content-left">
							<h2 data-aos="zoom-in-up" data-aos-delay="290">
								Our Unique Approach
							</h2>
							<p data-aos="zoom-in-up" data-aos-delay="100">
								We don't just want to be an agency; we aim to be your preferred
								creative partner.
								<br />
								Beyond the paramount importance of work quality, Wibuld places a
								premium on the human experience.
								<br />
								Our clients entrust us as their steadfast ally, industry
								professionals eagerly join our ranks, and every member of our
								team finds fulfillment in what they do.
								<br /> This vision is the compass steering our goals, processes,
								and culture—it's not just about the path; it's about the
								enriching journey.
							</p>
						</div>
						<div className="image-content">
							<img
								src={one}
								data-aos="zoom-in-up"
								data-aos-delay="490"
								alt="Another Design"
							/>
						</div>
					</div>
				</div>
				<div className="service-content container">
					<div className="content-container">
						<div className="text-content text-content-left">
							<h2 data-aos="zoom-in-up" data-aos-delay="290">
								Our Visionary Aspirations
							</h2>
							<p data-aos="zoom-in-up" data-aos-delay="100">
								We don't just want to be an agency; we aim to be your preferred
								creative partner. Beyond the paramount importance of work
								quality, Wibuld places a premium on the human experience. Our
								clients entrust us as their steadfast ally, industry
								professionals eagerly join our ranks, and every member of our
								team finds fulfillment in what they do. This vision is the
								compass steering our goals, processes, and culture—it's not just
								about the path; it's about the enriching journey.
							</p>
						</div>
						<div className="image-content">
							<img
								src={sup}
								data-aos="zoom-in-up"
								data-aos-delay="490"
								alt="Another Design"
							/>
						</div>
					</div>
				</div>

				<div className="service_black space_black">
					<div className="service-content container">
						<div className="text-content company-text">
							<h2 data-aos="zoom-in-up">
								Join us on this thrilling odyssey marked by creativity,
								collaboration, and perpetual evolution. At Wibuld, we don't just
								create websites; we architect digital experiences that etch a
								lasting legacy
							</h2>
						</div>
					</div>
					<div className="service-content container">
						<div className="content-container">
							<div className="text-content text-content-left">
								<h2 data-aos="zoom-in-up" data-aos-delay="290">
									Our Collective Journey
								</h2>
								<p data-aos="zoom-in-up" data-aos-delay="100">
									Wibuld's journey is a testament to our commitment to
									excellence. From our roots, we've evolved into a global force
									with boundless ambitions. Our international team of experts
									engineers solutions across scales, collaborating with tech
									titans, publishing moguls, and media giants. Our drive stems
									from the anticipation of tomorrow—the unsolved problems, the
									ingenious solutions, and the promise of creating
									transformative opportunities.
								</p>
							</div>
							<div className="image-content">
								<img
									src={j}
									data-aos="zoom-in-up"
									data-aos-delay="490"
									alt="Another Design"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<MiniFoot />
		</>
	);
};

export default Company;
