import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom"; // Add useLocation hook
import HoverVideoPlayer from "react-hover-video-player";
import PausedOverlay from "./PausedOverlay";
import projectsData from "./projects.json";
import "./ProjectList.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // Add this line

gsap.registerPlugin(ScrollTrigger); // Add this line

const ProjectList = () => {
	const location = useLocation(); // Get current location

	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	const { projects } = projectsData;
	const videoRefs = useMemo(() => ({}), []);
	const [userInteracted, setUserInteracted] = useState(false);

	const handleMouseEnter = (title) => {
		// Play the video when mouse enters and user has interacted
		if (userInteracted && videoRefs[title]) {
			videoRefs[title].play();
		}
	};

	const handleMouseLeave = (title) => {
		// Pause the video when mouse leaves
		if (videoRefs[title]) {
			videoRefs[title].pause();
			videoRefs[title].currentTime = 0;
		}
	};

	const handleUserInteraction = () => {
		// Set userInteracted to true when the user interacts with the document
		setUserInteracted(true);
	};

	useEffect(() => {
		return () => {
			Object.values(videoRefs).forEach((videoRef) => {
				if (videoRef) {
					videoRef.pause();
					videoRef.currentTime = 0;
				}
			});
		};
	}, [videoRefs]);

	return (
		<div
			data-aos="zoom-in-up"
			className="project-list"
			onMouseOver={handleUserInteraction}
		>
			{location.pathname === "/" && (
				<div className="ourwork">Featured Projects</div>
			)}{" "}
			{/* Render h1 only on home page */}
			{projects.map((project) => (
				<React.Fragment key={project.title}>
					<Link
						to={`/project/${project.title.toLowerCase().replace(/\s+/g, "-")}`}
						key={project.title}
						className="project-link"
					>
						<div className="project-details">
							<h3>{project.title}</h3>
						</div>
						<div
							className="project-item"
							onMouseEnter={() => handleMouseEnter(project.title)}
							onMouseLeave={() => handleMouseLeave(project.title)}
						>
							<HoverVideoPlayer
								videoSrc={project.videoPath}
								pausedOverlay={<PausedOverlay project={project} />}
								style={{ width: "100%" }}
								loadingOverlay={
									<div className="loading-overlay">
										<div className="loading-spinner" />
									</div>
								}
								videoRef={(el) => {
									videoRefs[project.title] = el;

									if (el) {
										el.addEventListener("loadeddata", () => {
											ScrollTrigger.refresh();
										});
									}
								}}
							/>
						</div>
					</Link>
					<p className="pro_p">{project.services.join(", ")}</p>
				</React.Fragment>
			))}
		</div>
	);
};

export default ProjectList;
