import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
	return (
		<nav className="mainmenu-nav">
			<ul className="mainmenu">
				<li>
					<Link to="/project">Project </Link>
				</li>
				<li>
					<Link to="/services">Services</Link>
				</li>
				{/* <li>
					<Link to="/blog">Blog</Link>
				</li> */}
				<li>
					<Link to={"/company"}>Company</Link>
				</li>
			</ul>
		</nav>
	);
};

export default Nav;
