import "./styles.css";
import { Link } from "react-router-dom";
import first from "./2.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect, useRef } from "react";

export const MiniFoot = () => {
	const containerRef = useRef();

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		const sections = gsap.utils.toArray(".panel");

		gsap.to(sections, {
			xPercent: -100 * (sections.length - 1),
			ease: "none",
			scrollTrigger: {
				trigger: ".containr",
				pin: true,
				scrub: 1,
				end: () => {
					const offsetWidth = containerRef.current
						? containerRef.current.offsetWidth
						: 0;
					return "+=" + offsetWidth;
				},
				onEnter: () => {
					const headerElement = document.querySelector(
						".axil-header.header-style-4 .axil-mainmenu.axil-sticky"
					);

					// Check if the headerElement is not null before accessing classList
					if (headerElement) {
						headerElement.classList.add("transparent-header");
					}
				},
				onLeaveBack: () => {
					const headerElement = document.querySelector(
						".axil-header.header-style-4 .axil-mainmenu.axil-sticky"
					);

					// Check if the headerElement is not null before accessing classList
					if (headerElement) {
						headerElement.classList.remove("transparent-header");
					}
				},
			},
		});
	}, []);

	return (
		<>
			<div ref={containerRef} className="containr">
				<section className="description panel blue">
					<h2 className="heading--270">We've got you!</h2>
				</section>
				<section className="panel red">
					<img src={first} className="floating-image" alt="Floating" />
					<br />
					<h2 className="msmall">We design with love</h2>
				</section>
				<section className="description panel blue">
					<h2 className="heading--270">WE CRAFT WITH CARE</h2>
				</section>
				<section className="panel red">
					<h2 className="heading--170">Let's embark on a new journey.</h2>
					<Link to={"/contact"} className="axil-btn btn-fill-white">
						Work with Us
					</Link>
				</section>
			</div>
		</>
	);
};
