import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./About.css";
import AOS from "aos";
import { GoPeople, GoRocket } from "react-icons/go";
import { GiSatelliteCommunication } from "react-icons/gi";
import "aos/dist/aos.css";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	// Second GSAP animation
	// About.js
	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		gsap.to(".about-section", {
			backgroundColor: "#101820",
			color: "white",
			scrollTrigger: {
				trigger: ".about-section",
				start: "center center",
				end: "top top",
				scrub: 0,
				delay: 1,
			},
		});
	}, []);

	useEffect(() => {
		gsap.utils.toArray(".about-items-f").forEach((item, index) => {
			gsap.to(item, {
				y: "150%", // Move down by 100% of its height
				duration: 100,
				delay: 1,
				opacity: 0, // Fade out
				scrollTrigger: {
					trigger: item,
					start: "center center", // Start animation when top of the element hits the bottom of the viewport
					end: "bottom top", // End animation when the element has scrolled to the top
					scrub: true,
				},
			});
		});
	}, []);

	return (
		<div className="about-section">
			<div className="text">
				<h5 data-aos="zoom-in-up">Crafting Brilliance</h5>
				<p
					className="paragraph__Paragraph-sc-ks1m1i-0 kODeEz"
					style={{
						opacity: 1,
						visibility: "inherit",
						transform: "translate3d(0px, 0px, 0.1px) rotate(0.01deg)",
					}}
				>
					We are not just a web design agency; we're architects of digital
					brilliance, our passion lies in creating stunning, user-centric
					websites that leave a lasting impact. But it's more than that.
					<br />
					<br />
					{/* <br />
					<br /> */}
					We are the craftsmen of innovation, weaving technology and creativity
					seamlessly to deliver websites that not only meet but exceed
					expectations.
					<br />
					<br />
					We're not just about aesthetics; we're about delivering results that
					go beyond expectations,each website we create is a masterpiece crafted
					with precision and care, discover the power of innovation with us.
				</p>
			</div>

			<div className="about-icons about-items">
				<div className="about-items-f">
					<GiSatelliteCommunication size={"60px"} />
					<br /> <br /> <br />
					<div className="about-items-s">
						Transparent
						<br /> Communication
						<br />
					</div>
				</div>
				<div className="about-items-f">
					<GoPeople size={"60px"} />
					<br />
					<br /> <br />
					<div className="about-items-s">
						Creative <br /> Team
						<br />
					</div>
				</div>
				<div className="about-items-f">
					<GoRocket size={"60px"} />
					<br />
					<br /> <br />
					<div className="about-items-s">
						Client <br /> Satisfaction
						<br />
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
