// ServicePage.js
import React, { useEffect } from "react";
import "../css/Service.css";
import gsap from "gsap";
import Navbar from "../../Nav/Navbar";
import sample from "./samp.mp4";
import web from "./web.jpeg";
import dev from "./dev.jpg";
import digital from "./digital.jpg";
import brand from "./brand.jpg";
import sup from "./sup.jpg";
import opt from "./opt.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { MiniFoot } from "../../MiniFoot.js/MiniFoot";
import { Helmet } from "react-helmet";

const ServicePage = () => {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	useEffect(() => {
		gsap.to(".service_black", {
			backgroundColor: "#101820",
			color: "white",
			scrollTrigger: {
				trigger: ".service_black",
				start: "center center",
				end: "top top", // Change the end position to "top top"
				scrub: 0, // Set scrub to 0 for an instant change
			},
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>Web Development Services - Wibuld</title>
				<meta
					name="description"
					content="Wibuld offers a wide range of web development services tailored to meet your business needs. From design to implementation, we've got you covered."
				/>
				<meta
					name="keywords"
					content="web development services, Wibuld, design, implementation"
				/>
			</Helmet>

			<Navbar />
			<div className="service-page">
				<div className="banner">
					<div className="banner-overlay"></div>
					<video autoPlay loop muted playsInline>
						<source src={sample} type="video/mp4" />
					</video>
					<div className="banner-text">
						<h1 data-aos="zoom-in-up">Where Innovative Design </h1>
						<h1 data-aos="zoom-in-up" data-aos-delay="450">
							Fuels Your Online Triumph
						</h1>
					</div>
				</div>

				<div className="service-content container">
					<div className="text-content text-content-right">
						<h2 id="mobile-app-and-web-design" data-aos="zoom-in-up">
							Mobile App & Web Design
						</h2>
						<p data-aos="zoom-in-up" data-aos-delay="400">
							Connect with your audience where they spend the most time. Our
							mobile app and web designs are meticulously crafted to deliver a
							seamless experience and a stunning appearance across all devices.
						</p>
						<ul data-aos="zoom-in-up" data-aos-delay="440">
							<li>Custom Web Design</li>
							<li>User Experience (UX)</li>
							<li>UI Prototyping</li>
							<li>Mobile & Web Apps</li>
						</ul>
					</div>
					<div className="image-content">
						<img
							src={web}
							data-aos="zoom-in-up"
							data-aos-delay="490"
							alt="Mobile App & Web Design"
						/>
					</div>
				</div>
				<div className="service-content container">
					<div className="content-container">
						<div className="text-content text-content-left">
							<h2 data-aos="zoom-in-up" data-aos-delay="290">
								Web Development
							</h2>
							<p data-aos="zoom-in-up" data-aos-delay="100">
								Empower your online presence with a platform that's effortlessly
								updatable. We ensure a flawless launch through extensive testing
								in simulated live environments.
							</p>
							<ul data-aos="zoom-in-up" data-aos-delay="440">
								<li>WordPress CMS</li>
								<li>Shopify CMS</li>
							</ul>
						</div>
						<div className="image-content">
							<img
								src={dev}
								data-aos="zoom-in-up"
								data-aos-delay="490"
								alt="Another Design"
							/>
						</div>
					</div>
				</div>
				{/* new 2 */}

				<div className="service-content container">
					<div className="text-content text-content-right">
						<h2 data-aos="zoom-in-up">Digital Marketing</h2>
						<p data-aos="zoom-in-up" data-aos-delay="400">
							Maximize your resources with strategic digital marketing plans
							that revolve around your objectives and evolve with ongoing
							optimization.
						</p>
						<ul data-aos="zoom-in-up" data-aos-delay="440">
							<li>Digital Marketing Strategy</li>
							<li>Social Media Management</li>
							<li>Search Engine Optimization (SEO)</li>
							<li>Paid Search (PPC)</li>
						</ul>
					</div>
					<div className="image-content">
						<img
							src={digital}
							data-aos="zoom-in-up"
							data-aos-delay="490"
							alt="Mobile App & Web Design"
						/>
					</div>
				</div>
				<div className="service_black">
					<div className="service-content container">
						<div className="content-container">
							<div className="text-content text-content-left">
								<h2 data-aos="zoom-in-up" data-aos-delay="290">
									Branding
								</h2>
								<p data-aos="zoom-in-up" data-aos-delay="100">
									Forge a profound emotional connection with your audience
									through a compelling brand. We empower you to create a lasting
									impression on your customers.
								</p>
								<ul data-aos="zoom-in-up" data-aos-delay="440">
									<li>Logo Design</li>
									<li>Corporate Identity</li>
									<li>Brand Standards Guidelines/Content Writing</li>
								</ul>
							</div>
							<div className="image-content">
								<img
									src={brand}
									data-aos="zoom-in-up"
									data-aos-delay="490"
									alt="Another Design"
								/>
							</div>
						</div>
					</div>
					{/* new 3 */}

					<div className="service-content container">
						<div className="text-content text-content-right">
							<h2 data-aos="zoom-in-up">Website Optimization</h2>
							<p data-aos="zoom-in-up" data-aos-delay="400">
								Drive desired actions from your website visitors. We
								meticulously measure, test, and optimize your site to yield
								tangible results.
							</p>
							<ul data-aos="zoom-in-up" data-aos-delay="440">
								<li>Conversion Optimization (CRO)</li>
								<li>SWebsite Performance</li>
								<li>Usability Consulting</li>
							</ul>
						</div>
						<div className="image-content">
							<img
								src={opt}
								data-aos="zoom-in-up"
								data-aos-delay="490"
								alt="Mobile App & Web Design"
							/>
						</div>
					</div>
					<div className="service-content container">
						<div className="content-container">
							<div className="text-content text-content-left">
								<h2 data-aos="zoom-in-up" data-aos-delay="290">
									Website Support
								</h2>
								<p data-aos="zoom-in-up" data-aos-delay="100">
									Every website deserves care and attention. Our human support
									and technological expertise are here to ensure your website is
									always at its best.
								</p>
								<ul data-aos="zoom-in-up" data-aos-delay="440">
									<li>Site Maintenance</li>
									<li>Hosting & Monitoring</li>
									<li>Backup & Recovery</li>
									<li>Updates & Changes</li>
								</ul>
							</div>
							<div className="image-content">
								<img
									src={sup}
									data-aos="zoom-in-up"
									data-aos-delay="490"
									alt="Another Design"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<MiniFoot />
		</>
	);
};

export default ServicePage;
