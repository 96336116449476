import React from "react";
import "./MiniFoot.css";
import { Link } from "react-router-dom";
export const MiniFoot = () => {
	return (
		<>
			<div className="mini-f">
				<section>
					<div className="hr"></div>
					<h2>Let's embark on a new journey.</h2>
					<Link to={"/contact"} className="axil-btn btn-fill-white">
						Work with Us
					</Link>
				</section>
			</div>
		</>
	);
};
