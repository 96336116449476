import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "./webextimate.css";

const PriceEstimator = () => {
	const [numPages, setNumPages] = useState(1);
	const [style, setStyle] = useState(0);
	const [copywriting, setCopywriting] = useState(0);
	const [seo, setSeo] = useState(0);
	const [responsiveDesign, setResponsiveDesign] = useState(0);
	const [databaseIntegration, setDatabaseIntegration] = useState(0);
	const [ecommerceFunctionality, setEcommerceFunctionality] = useState(0);
	const [cms, setCms] = useState(0);
	const [totalPriceRange, setTotalPriceRange] = useState({ min: 0, max: 0 });
	const [logoDataUrl, setLogoDataUrl] = useState("");

	const calculatePriceRange = () => {
		let basePrice = 500;
		let minPrice = basePrice;
		let maxPrice = basePrice;

		// Number of Pages
		if (numPages === 1) {
			minPrice += 100;
			maxPrice += 300;
		} else if (numPages === 2) {
			minPrice += 200;
			maxPrice += 400;
		} else if (numPages === 3) {
			minPrice += 300;
			maxPrice += 500;
		} else if (numPages === 4) {
			minPrice += 400;
			maxPrice += 600;
		}

		// Style of Design
		if (style === 1) {
			minPrice += 200;
			maxPrice += 250;
		} else if (style === 2) {
			minPrice += 400;
			maxPrice += 450;
		} else if (style === 3) {
			minPrice += 600;
			maxPrice += 650;
		}

		// Copywriting
		if (copywriting === 1) {
			minPrice += 70;
			maxPrice += 150;
		} else if (copywriting === 2) {
			minPrice += 180;
			maxPrice += 250;
		} else if (copywriting === 3) {
			minPrice += 260;
			maxPrice += 350;
		}

		// SEO
		if (seo === 1) {
			minPrice += 100;
			maxPrice += 180;
		} else if (seo === 2) {
			minPrice += 400;
			maxPrice += 450;
		} else if (seo === 3) {
			minPrice += 600;
			maxPrice += 650;
		}

		// Responsive Design
		if (responsiveDesign === 1) {
			minPrice += 300;
			maxPrice += 450;
		}

		// Database Integration
		if (databaseIntegration === 1) {
			minPrice += 200;
			maxPrice += 250;
		} else if (databaseIntegration === 2) {
			minPrice += 400;
			maxPrice += 450;
		} else if (databaseIntegration === 3) {
			minPrice += 600;
			maxPrice += 650;
		}

		// E-commerce Functionality
		if (ecommerceFunctionality === 1) {
			minPrice += 200;
			maxPrice += 250;
		} else if (ecommerceFunctionality === 2) {
			minPrice += 400;
			maxPrice += 450;
		} else if (ecommerceFunctionality === 3) {
			minPrice += 800;
			maxPrice += 850;
		}

		// CMS
		if (cms === 1) {
			minPrice += 150;
			maxPrice += 200;
		} else if (cms === 2) {
			minPrice += 190;
			maxPrice += 289;
		} else if (cms === 3) {
			minPrice += 350;
			maxPrice += 650;
		}

		setTotalPriceRange({ min: minPrice, max: maxPrice });
	};

	const loadLogo = () => {
		const logoUrl = "/logo.png"; // Path to your logo
		const img = new Image();
		img.src = logoUrl;
		img.onload = () => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);
			setLogoDataUrl(canvas.toDataURL("image/png"));
		};
	};

	const generatePDF = () => {
		const pdf = new jsPDF();

		// Calculate dates
		const today = new Date();
		const validDate = new Date();
		validDate.setDate(today.getDate() + 14);

		const dateStr = today.toLocaleDateString();
		const validDateStr = validDate.toLocaleDateString();

		// Header
		if (logoDataUrl) {
			pdf.addImage(logoDataUrl, "PNG", 10, 10, 20, 20); // Logo at top-left
		}
		pdf.setFontSize(10);
		pdf.text(`Date: ${dateStr}`, 200, 15, { align: "right" });
		pdf.text(`Valid Until: ${validDateStr}`, 200, 25, { align: "right" });
		pdf.text("Email: hello@wibuld.com", 200, 35, { align: "right" });

		// Title
		pdf.setFontSize(20);
		pdf.setFont("Helvetica", "bold");
		pdf.text("Project Estimation", 105, 70, { align: "center" });

		// Details
		pdf.setFont("Helvetica", "normal");
		pdf.setFontSize(14);
		pdf.text("1. Number of Pages:", 10, 90);
		pdf.text(
			numPages === 1
				? "1-10"
				: numPages === 2
				? "10-20"
				: numPages === 3
				? "30-40"
				: "40+",
			190,
			90,
			{ align: "right" }
		);

		pdf.text("2. Style of Design:", 10, 100);
		pdf.text(
			style === 1
				? "Simple"
				: style === 2
				? "Basic"
				: style === 3
				? "Advanced"
				: "None",
			190,
			100,
			{ align: "right" }
		);

		pdf.text("3. Copywriting:", 10, 110);
		pdf.text(
			copywriting === 1
				? "1-5"
				: copywriting === 2
				? "5-10"
				: copywriting === 3
				? "10-20"
				: "None",
			190,
			110,
			{ align: "right" }
		);

		pdf.text("4. SEO", 10, 120);
		pdf.text(
			seo === 1
				? "Basic"
				: seo === 2
				? "Advanced"
				: seo === 3
				? "Premium"
				: "None",
			190,
			120,
			{ align: "right" }
		);

		pdf.text("5. Responsive Design:", 10, 130);
		pdf.text(responsiveDesign === 1 ? "Yes" : "No", 190, 130, {
			align: "right",
		});

		pdf.text("6. Database Integration:", 10, 140);
		pdf.text(
			databaseIntegration === 1
				? "Basic"
				: databaseIntegration === 2
				? "Advanced"
				: databaseIntegration === 3
				? "Full"
				: "None",
			190,
			140,
			{ align: "right" }
		);

		pdf.text("7. E-commerce Functionality:", 10, 150);
		pdf.text(
			ecommerceFunctionality === 1
				? "Basic"
				: ecommerceFunctionality === 2
				? "Advanced"
				: ecommerceFunctionality === 3
				? "Enterprise"
				: "None",
			190,
			150,
			{ align: "right" }
		);

		pdf.text("8. CMS:", 10, 160);
		pdf.text(
			cms === 1
				? "WordPress"
				: cms === 2
				? "Shopify"
				: cms === 3
				? "Custom"
				: "None",
			190,
			160,
			{ align: "right" }
		);

		// Set font size and style for 'Terms and Conditions'
		pdf.setFontSize(16);
		pdf.setFont("Helvetica", "bold");
		pdf.text("Terms and Conditions", 10, 220);

		// Set font size and style for the quote range text
		pdf.setFont("Helvetica", "normal");
		pdf.setFontSize(14); // Match the font size to align properly

		// Position the quote range text on the right side
		const quoteRangeX = 120; // Adjust X position based on PDF width
		pdf.text(
			`Estimated Range: $${totalPriceRange.min} - $${totalPriceRange.max}`,
			quoteRangeX,
			220
		);

		// Set font size and style for the additional text
		pdf.setFontSize(12); // Adjust as needed
		pdf.setFont("Helvetica", "normal");

		// Determine the vertical position for the additional text
		const lineHeight = 7; // Adjust line height as needed
		const additionalTextY = 230; // Position for the first line of additional text

		// Add the additional text in two lines below 'Terms and Conditions'
		pdf.text("Total amount appeared in this Estimation", 10, additionalTextY);
		pdf.text(
			"Shall be paid before the valid date.",
			10,
			additionalTextY + lineHeight
		);

		pdf.save("Wibuld Estimated Price.pdf");
	};

	useEffect(() => {
		loadLogo();
	}, []);

	return (
		<div className="quote-calculator">
			<h1 className="title">Project Quote Calculator</h1>
			<p className="sub-title">
				Adjust the sliders below to specify your needs, then click the 'See
				Pricing' button to receive an instant, free quote.
			</p>
			<div className="input-g">
				<label>Number of Pages:</label>
				<input
					className="slider"
					type="range"
					min="1"
					max="4"
					value={numPages}
					onChange={(e) => setNumPages(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{numPages === 1
						? "1-10"
						: numPages === 2
						? "10-20"
						: numPages === 3
						? "30-40"
						: "40+"}
				</div>
			</div>

			<div className="input-g">
				<label>Style of Design:</label>
				<input
					className="slider"
					type="range"
					min="0"
					max="3"
					value={style}
					onChange={(e) => setStyle(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{style === 1
						? "Simple"
						: style === 2
						? "Basic"
						: style === 3
						? "Advanced"
						: "None"}
				</div>
			</div>

			<div className="input-g">
				<label>Copywriting:</label>
				<input
					className="slider"
					type="range"
					min="0"
					max="3"
					value={copywriting}
					onChange={(e) => setCopywriting(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{copywriting === 1
						? "1-5"
						: copywriting === 2
						? "5-10"
						: copywriting === 3
						? "10-20"
						: "None"}
				</div>
			</div>

			<div className="input-g">
				<label>SEO</label>
				<input
					className="slider"
					type="range"
					min="0"
					max="3"
					value={seo}
					onChange={(e) => setSeo(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{seo === 1
						? "Basic"
						: seo === 2
						? "Advanced"
						: seo === 3
						? "Premium"
						: "None"}
				</div>
			</div>

			<div className="input-g">
				<label>Responsive Design:</label>
				<input
					className="slider"
					type="range"
					min="0"
					max="1"
					value={responsiveDesign}
					onChange={(e) => setResponsiveDesign(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{responsiveDesign === 1 ? "Yes" : "No"}
				</div>
			</div>

			<div className="input-g">
				<label>Database Integration:</label>
				<input
					className="slider"
					type="range"
					min="0"
					max="3"
					value={databaseIntegration}
					onChange={(e) => setDatabaseIntegration(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{databaseIntegration === 1
						? "Basic"
						: databaseIntegration === 2
						? "Advanced"
						: databaseIntegration === 3
						? "Full"
						: "None"}
				</div>
			</div>

			<div className="input-g">
				<label>E-commerce Functionality:</label>
				<input
					className="slider"
					type="range"
					min="0"
					max="3"
					value={ecommerceFunctionality}
					onChange={(e) => setEcommerceFunctionality(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{ecommerceFunctionality === 1
						? "Basic"
						: ecommerceFunctionality === 2
						? "Advanced"
						: ecommerceFunctionality === 3
						? "Enterprise"
						: "None"}
				</div>
			</div>

			<div className="input-g">
				<label>CMS:</label>
				<input
					className="slider"
					type="range"
					min="0"
					max="3"
					value={cms}
					onChange={(e) => setCms(parseInt(e.target.value))}
				/>
				<div className="range-value">
					{cms === 1
						? "WordPress"
						: cms === 2
						? "Shopify"
						: cms === 3
						? "Custom"
						: "None"}
				</div>
			</div>

			<div className="price-range">
				{totalPriceRange.min !== 0 ? (
					<p>
						Price Range: ${totalPriceRange.min} - ${totalPriceRange.max}
					</p>
				) : null}
			</div>

			<div className="button-group">
				<button onClick={calculatePriceRange}>Calculate Price</button>
				{totalPriceRange.min !== 0 ? (
					<button onClick={generatePDF}>Download Generated PDF</button>
				) : null}
			</div>
		</div>
	);
};

export default PriceEstimator;
