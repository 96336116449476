import React from "react";
import "./work.css";
import ProjectList from "./ProjectList";
import Navbar from "../Nav/Navbar";
import { MiniFoot } from "../MiniFoot.js/MiniFoot";
import { Helmet } from "react-helmet";
const Work = () => {
	return (
		<>
			<Helmet>
				<title>Wibuld Projects - Web Development Portfolio</title>
				<meta
					name="description"
					content="Explore our diverse portfolio of web development projects at Wibuld. Discover innovative solutions and cutting-edge technologies."
				/>
				<meta
					name="keywords"
					content="Wibuld projects, web development portfolio, innovative solutions, cutting-edge technologies"
				/>
			</Helmet>

			<div className="work-container">
				<Navbar />
				<div className="workb">
					<ProjectList />
				</div>
			</div>
			<MiniFoot />
		</>
	);
};

export default Work;
