import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/scss/app.scss";
import "bootstrap/dist/css/bootstrap.css";
import ScrollToTop from "./ScrollToTop";
import Home from "./component/Home/Home";
import Footer from "./component/Footer/Footer";
import SmoothScroll from "./SmoothScroll";
import ServicePage from "./component/Pages/Service/Service";
import Company from "./component/Pages/About/Company";
import ContactPage from "./component/Pages/Contact/Contact";
import SubmittedPage from "./component/Pages/Contact/Submitted";
import ProjectDetail from "./component/Projects/ProjectDetail";
import projectsData from "./component/Projects/projects.json";
import Work from "./component/Projects/work";
import Error from "./component/Error/Error";
import { Helmet } from "react-helmet";
import EstimatePage from "./component/Pages/WebEstimate/Estimator";

function App() {
	return (
		<>
			<BrowserRouter>
				<Helmet>
					<title>Striking Web Design and Branding Solutions - Wibuld</title>
					<meta
						name="description"
						content="Elevate your digital presence with Wibuld's striking web design and branding solutions. Crafted for seamless experiences and a lasting impression across all devices."
					/>
					<meta
						name="keywords"
						content="web design, branding solutions, striking design, seamless experiences, lasting impression"
					/>
				</Helmet>

				<ScrollToTop />
				<Routes>
					<Route
						path="/"
						element={
							<>
								<SmoothScroll />
								<Home />
							</>
						}
					/>
					<Route exact path="services" element={<ServicePage />} />
					<Route exact path="company" element={<Company />} />
					<Route exact path="contact" element={<ContactPage />} />
					<Route path="/contact/submitted" exact element={<SubmittedPage />} />
					<Route exact path="project" element={<Work />} />
					<Route exact path="pricing/website-estimator" element={<EstimatePage />} />
					{projectsData.projects.map((project) => (
						<Route
							key={project.title}
							path={`project/${project.title
								.toLowerCase()
								.replace(/\s+/g, "-")}`}
							element={<ProjectDetail project={project} />}
						/>
					))}

					<Route path="*" element={<Error />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</>
	);
}

export default App;
