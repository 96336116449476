import React, { useState } from "react";
import "../css/ContactPage.css"; // Your CSS file
import Navbar from "../../Nav/Navbar";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

const ContactPage = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		businessName: "",
		helpWith: [],
		budget: "",
		message: "",
	});
	const [responseMessage, setResponseMessage] = useState("");

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (type === "checkbox") {
			setFormData((prevData) => ({
				...prevData,
				[name]: checked
					? [...prevData[name], value]
					: prevData[name].filter((item) => item !== value),
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(
				"https://wibuld.com/send-mail.php",
				formData,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);

			if (response.status === 200) {
				setResponseMessage("Email sent successfully!");
				setFormData({
					name: "",
					email: "",
					phone: "",
					businessName: "",
					helpWith: [],
					budget: "",
					message: "",
				});
				navigate("/contact/submitted");
			} else {
				setResponseMessage("Failed to send email. Please try again later.");
			}
		} catch (error) {
			setResponseMessage("Error sending email. Please try again.");
		}
	};

	return (
		<>
			<Helmet>
				<title>
					Contact Wibuld - Get in Touch for Web Development Services
				</title>
				<meta
					name="description"
					content="Contact Wibuld for inquiries about our web development services. We're here to assist you in bringing your digital ideas to life."
				/>
				<meta
					name="keywords"
					content="Wibuld contact, web development services, get in touch, inquiries"
				/>
			</Helmet>

			<Navbar />
			<div className="contact-container">
				<div className="contact-card">
					<h2>Let's engage in a conversation about your ideas</h2>
					<p>
						We're eager to learn more about your needs. Share some details
						below, and our team will connect with you shortly. Should you prefer
						a call, you can contact us at{" "}
						<a href="tel:346-291-8330">346-291-8330</a>.
					</p>
					<form onSubmit={handleSubmit}>
						{/* Name */}
						<div className="form-group">
							<label htmlFor="name">Your name</label>
							<input
								type="text"
								id="name"
								name="name"
								value={formData.name}
								onChange={handleChange}
								placeholder="Your name"
								required
							/>
						</div>

						{/* Email */}
						<div className="form-group">
							<label htmlFor="email">Email address</label>
							<input
								type="email"
								id="email"
								name="email"
								value={formData.email}
								onChange={handleChange}
								placeholder="Email address"
								required
							/>
						</div>

						{/* Phone */}
						<div className="form-group">
							<label htmlFor="phone">Phone</label>
							<input
								type="tel"
								id="phone"
								name="phone"
								value={formData.phone}
								onChange={handleChange}
								placeholder="Your phone number"
							/>
						</div>

						{/* Business Name */}
						<div className="form-group">
							<label htmlFor="businessName">Business name</label>
							<input
								type="text"
								id="businessName"
								name="businessName"
								value={formData.businessName}
								onChange={handleChange}
								placeholder="Business name"
							/>
						</div>

						{/* Help With (Checkboxes) */}
						<p>What do you need help with?</p>
						<div className="form-group">
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Web Design"
										checked={formData.helpWith.includes("Web Design")}
										onChange={handleChange}
									/>
									Web Design
								</label>
							</div>
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Web Development"
										checked={formData.helpWith.includes("Web Development")}
										onChange={handleChange}
									/>
									Web Development
								</label>
							</div>
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Digital Marketing"
										checked={formData.helpWith.includes("Digital Marketing")}
										onChange={handleChange}
									/>
									Digital Marketing
								</label>
							</div>
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Branding"
										checked={formData.helpWith.includes("Branding")}
										onChange={handleChange}
									/>
									Branding
								</label>
							</div>
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Website Optimization"
										checked={formData.helpWith.includes("Website Optimization")}
										onChange={handleChange}
									/>
									Website Optimization
								</label>
							</div>
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Website Support"
										checked={formData.helpWith.includes("Website Support")}
										onChange={handleChange}
									/>
									Website Support
								</label>
							</div>
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Application Design"
										checked={formData.helpWith.includes("Application Design")}
										onChange={handleChange}
									/>
									Application Design
								</label>
							</div>
							<div>
								<label>
									<input
										type="checkbox"
										name="helpWith"
										value="Others"
										checked={formData.helpWith.includes("Others")}
										onChange={handleChange}
									/>
									Others
								</label>
							</div>
						</div>

						{/* Budget (Optional) */}
						<div className="form-group">
							<label htmlFor="budget">Budget (Optional)</label>
							<input
								type="text"
								id="budget"
								name="budget"
								value={formData.budget}
								onChange={handleChange}
								placeholder="Your budget"
							/>
						</div>
						{/* Price Estimator Links */}
						<div className="form-group">
							<p>
								Do you need to know your budget? Check out our{" "}
								<a href="/pricing/website-estimator">
									Web Design Price Estimator
								</a>
								{/* ,{" "}
								<a href="/pricing/website-optimization">
									Website Optimization Prices
								</a>
								, and{" "}
								<a href="/pricing/website-support">Website Support Prices</a>. */}
							</p>
						</div>

						{/* Additional Message */}
						<div className="form-group">
							<label htmlFor="message">Any other info you’d like to add?</label>
							<textarea
								id="message"
								name="message"
								rows="4"
								value={formData.message}
								onChange={handleChange}
								placeholder="Type in your message here"
							></textarea>
						</div>

						

						{/* Submit Button */}
						<button type="submit">Submit</button>
					</form>
					{responseMessage && <p>{responseMessage}</p>}
				</div>
			</div>
		</>
	);
};

export default ContactPage;
