import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedin, FaInstagram, FaDribbble } from "react-icons/fa";
import ServiceData from "./ServiceMain.json";
// import { slugify } from "./utils";
import "./footer.css";
const getServiceData = ServiceData;

const Footer = ({ parentClass }) => {
	return (
		<footer className={`footer-area ${parentClass}`}>
			<div className="container">
				<div className="footer-top">
					<div className="footer-social-link">
						<ul className="list-unstyled">
							<li>
								<Link to="https://twitter.com/wibuldhq">
									<FaTwitter />
								</Link>
							</li>
							<li>
								<Link to="https://www.linkedin.com/company/wibuld/">
									<FaLinkedin />
								</Link>
							</li>
							<li>
								<Link to="https://www.instagram.com/wibuldhq">
									<FaInstagram />
								</Link>
							</li>
							<li>
								<Link to="https://dribbble.com/">
									<FaDribbble />
								</Link>
							</li>
							{/* <li>
								<Link to="https://www.behance.net/">
									<FaBehance />
								</Link>
							</li> */}
						</ul>
					</div>
				</div>
				<div className="footer-main">
					<div className="row">
						<div className="col-xl-6 col-lg-5">
							<div className="footer-widget ">
								<div className="footer-contactus">
									<h2 className="title">Lets Talk!</h2>
									<h4>
										<a href="mailto:hello@wibuld.com">hello@wibuld.com</a>
									</h4>
									<h4>
										<a href="tel:346-291-8330">Call 346-291-8330</a>
									</h4>
									<div className="addy">9432 Katy Fwy , Houston, Texas</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-7">
							<div className="row">
								<div className="col-sm-6">
									<div className="footer-widget">
										<h6 className="widget-title">Services</h6>
										<div className="footer-menu-link">
											<ul className="list-unstyled">
												{getServiceData.slice(0, 6).map((data, index) => (
													<li key={index}>
														<Link to={process.env.PUBLIC_URL + `/services`}>
															{data.title}
														</Link>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="footer-widget">
										<h6 className="widget-title">Resourses</h6>
										<div className="footer-menu-link">
											<ul className="list-unstyled">
												<li>
													<Link to={process.env.PUBLIC_URL + "/company"}>
														Company
													</Link>
												</li>
												<li>
													{" "}
													<Link to={process.env.PUBLIC_URL + "/project"}>
														Case Studies
													</Link>
												</li>
												<li>
													{" "}
													<Link to={process.env.PUBLIC_URL + "/pricing/website-estimator"}>
														Website Estimator
													</Link>
												</li>
												{/* <li>
													<Link
														to={process.env.PUBLIC_URL + "/careers"}
													>
														Careers
													</Link>
												</li> */}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="row">
						<div className="col-md-6">
							<div className="footer-copyright">
								<span className="copyright-text">
									© {new Date().getFullYear()} Wibuld
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
