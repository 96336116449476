import React, { useState, useEffect, useRef } from "react";
import "./ToolkitSection.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer"; // Import react-intersection-observer
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

gsap.registerPlugin(ScrollTrigger);

const ToolkitSection = () => {
	const [inViewRef, inView] = useInView();
	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		// Use gsap here to animate the shape when the component mounts
		gsap.fromTo(
			".index-features__tab-head",
			{ opacity: 0 },
			{ opacity: 1, duration: 1.9, delay: 0.5 }
		);
	}, []);
	const sliderRef = useRef(null);
	const [toolkits] = useState([
		{
			type: "WebDesign",
			icon: "./images/webDesign/1.png",
			icon_o: "./images/webDesign/1n.png",
			label: "Web Design",
			title:
				"Our designs are crafted to deliver a seamless and stunning experience",
			description: [
				"Custom Web Design",
				"User Experience (UX)",
				"UI Prototyping",
				"Mobile Apps",
				"Web Apps",
			],
			link: "/signup/?src=main_slider&tk=seo&redirect_to=/seo/",
			imgUrl: "/images/webDesign/DXKg.gif",
		},
		{
			type: "WebDev",
			icon: "./images/webDev/1.png",
			icon_o: "./images/webDev/1n.png",
			label: "Web Development",
			title:
				"Empower your online presence with a platform that's effortlessly updatable.",
			description: [
				"WordPress CMS",
				"Shopify CMS",
				"Organic traffic with our complete and easy SEO tools & workflow",
			],
			link: "/signup/?src=main_slider&tk=seo&redirect_to=/seo/",
			imgUrl: "/images/webDev/Owm.gif",
		},
		{
			type: "DigitalMarketing",
			icon: "./images/DigitalM/1.png",
			icon_o: "./images/DigitalM/1n.png",
			label: "Digital Marketing",
			title:
				"Maximize your resources with strategic plans that revolves around your objectives.",
			description: [
				"Digital Marketing Strategy",
				"Social Media Management",
				"Search Engine Optimization (SEO)",
				"Paid Search (PPC)"	
			],
			link: "/signup/?src=main_slider&tk=seo&redirect_to=/seo/",
			imgUrl: "/images/DigitalM/AvTp.gif",
		},
		{
			type: "Branding",
			icon: "./images/Branding/1.png",
			icon_o: "./images/Branding/1n.png",
			label: "Branding",
			title: "We empower you to create a lasting impression on your customers.",
			description: [
				"Logo Design",
				"Corporate Identity",
				"Brand Standards Guidelines/Content Writing",
			],
			link: "/signup/?src=main_slider&tk=seo&redirect_to=/seo/",
			imgUrl: "./images/Branding/branding.gif",
		},
		{
			type: "WebOpt",
			icon: "./images/WebOpt/1.png",
			icon_o: "./images/WebOpt/1n.png",
			label: "Website Optimization",
			title:
				"We measure, test, and optimize your site to yield tangible results.",
			description: [
				"Conversion Optimization (CRO)",
				"Website Performance",
				"Usability Consulting",
			],
			link: "/signup/?src=main_slider&tk=seo&redirect_to=/seo/",
			imgUrl: "/images/WebOpt/website.gif",
		},
		{
			type: "WebSup",
			icon: "./images/WebSub/1.png",
			icon_o: "./images/WebSub/1n.png",
			label: "Website Support",
			title: "Every website deserves care and attention.",
			description: [
				"Site Maintenance",
				"Hosting & Monitoring",
				"Backup & Recovery",
				"Updates & Changes",
			],
			link: "/signup/?src=main_slider&tk=seo&redirect_to=/seo/",
			imgUrl: "/images/WebSub/9ff2.gif",
		},
	]);

	const [activeSlide, setActiveSlide] = useState(0); // Track the active slide index
	useEffect(() => {
		const icons = gsap.utils.toArray(".index-features__tab-head");
		gsap.set(icons, { y: "-135%" });

		const featuresSlider = document.querySelector(".index-features-slider");
		const toggleSliderClass = (expanded) => {
			if (expanded) {
				featuresSlider.classList.add("index-features-slider-expanded");
			} else {
				featuresSlider.classList.remove("index-features-slider-expanded");
			}
		};

		ScrollTrigger.create({
			trigger: ".index-features-slider",
			start: "top center",
			end: "center center",
			onEnter: () => {
				gsap.to(icons, {
					y: 0,
					opacity: 1,
					duration: 1,
					stagger: 0.2,
					ease: "power4.out",
				});

				gsap.to(".index-features__tab-label", {
					color: "#000",
					fontWeight: "normal",
					duration: 1,
				});

				gsap.to(featuresSlider, {
					y: "-135px",
					duration: 1,
					ease: "power4.out",
				});
				toggleSliderClass(true);
			},
			onLeave: () => {
				gsap.to(".index-features__tab-label", {
					color: "#fff",
					fontWeight: "bold",
					duration: 1,
				});
			},
			onLeaveBack: () => {
				gsap.to(icons, {
					y: "-135%",
					opacity: 1,
					duration: 1,
					stagger: 0.2,
					ease: "power4.out",
				});

				gsap.to(".index-features__tab-label", {
					color: "#fff",
					fontWeight: "bold",
					duration: 1,
				});

				gsap.to(featuresSlider, {
					y: 0,
					duration: 1,
					ease: "power4.out",
				});
				toggleSliderClass(false);
			},
		});
		ScrollTrigger.refresh();
	}, []);

	// ... (rest of the code)

	const handleTabClick = (index) => {
		setActiveSlide(index);
		if (sliderRef.current) {
			sliderRef.current.slickGoTo(index);
		}
	};
	const sliderSettings = {
		dots: false,
		arrows: false,
		infinite: true,
		draggable: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		beforeChange: (_, nextSlide) => {
			setActiveSlide(nextSlide);
			// Pause the currently playing GIF when switching slides
			if (toolkits[activeSlide].gifRef.current) {
				toolkits[activeSlide].gifRef.current.src = toolkits[activeSlide].imgUrl;
			}
			// Start playing the GIF if it's in view
			if (inView && toolkits[currentSlide].gifRef.current) {
				playGifOnce(
					toolkits[currentSlide].gifRef,
					toolkits[currentSlide].imgUrl
				);
			}
		},
		afterChange: (currentSlide) => {
			setCurrentSlide(currentSlide); // Update the current slide index

			// Pause the currently playing GIF when switching slides
			if (toolkits[activeSlide].gifRef.current) {
				toolkits[activeSlide].gifRef.current.src = toolkits[activeSlide].imgUrl;
			}

			// Start playing the GIF if it's in view
			if (inView && toolkits[currentSlide].gifRef.current) {
				playGifOnce(
					toolkits[currentSlide].gifRef,
					toolkits[currentSlide].imgUrl
				);
			}
		},
	};
	const playGifOnce = (gifRef, imgUrl) => {
		if (gifRef && gifRef.current) {
			gifRef.current.src = imgUrl; // Set the GIF source to play it
			gifRef.current.addEventListener("load", () => {
				gifRef.current.removeEventListener("load", null); // Remove the load event listener
				setTimeout(() => {
					gifRef.current.currentTime = 0; // Reset the GIF playback to the beginning
					gifRef.current.play(); // Start playing the GIF again
				}, gifRef.current.duration * 1000); // Change the duration to control how long the GIF plays
			});
		}
	};

	return (
		<div className="index-features" data-scroll="top-slider">
			<div className="index-features-slider">
				<div className="index-features__tabs" role="tablist">
					{toolkits.map((toolkit, index) => (
						<button
							key={toolkit.type}
							className={`index-features__tab js-swiper-tab ${
								activeSlide === index ? "index-features__tab--active" : ""
							}`}
							onClick={() => handleTabClick(index)}
						>
							<div className="index-features__tab-head">
								<img
									className="index-features__tab-icon"
									width="64"
									height="64"
									src={activeSlide === index ? toolkit.icon : toolkit.icon_o}
									alt="icons"
								/>
								<div className="index-features__tab-label">{toolkit.label}</div>
							</div>
						</button>
					))}
				</div>
				<div className="index-features-content js-index-features">
					<Slider {...sliderSettings} ref={sliderRef}>
						{toolkits.map((toolkit) => (
							<div key={toolkit.type} className="index-features-item">
								<div className="index-features-item__inner">
									<div className="label-mob">
										<h1 className="title">{toolkit.label}</h1>
									</div>
									<div className="index-features-item__img-wrap">
										<picture>
											{/* Trying to get used to comments- lets say img bellow */}
											<img
												ref={(el) => {
													toolkit.gifRef = el;
													inViewRef(el);
												}}
												src={toolkit.imgUrl}
												alt=""
												width="560"
												height="472"
												className="index-features-item__img"
											/>
										</picture>
									</div>
									<div className="index-features-item__left">
										<h4>{toolkit.title}</h4>
										<h3 className="index-features-item__title">
											<ul>
												{toolkit.description.map((item, idx) => (
													<li key={idx}>{item}</li>
												))}
											</ul>
										</h3>
										<ul className="index-features-item__text index-text">
											{/* ... */}
										</ul>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default ToolkitSection;
