import React from "react";
import { Link } from "react-router-dom";
import "./Error.css";
import astronautImg from "./astronaut.svg";
import planetImg from "./planet.svg";

const Error = () => {
	return (
		<>
			<div className="permission_denied">
				<div id="tsparticles"></div>
				<div className="denied__wrapper">
					<h1>404</h1>
					<h3>Hmm, This page doesn't exist.</h3>
					<img id="astronaut" src={astronautImg} alt="Astronaut" />


						<Link className="denied__link" to="/">Go Home</Link>
				

					<img id="planet" src={planetImg} alt="Planet" />
				</div>
			</div>
		</>
	);
};

export default Error;
