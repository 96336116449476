import React from "react";
import { Link } from "react-router-dom";
import "../css/ContactPage.css"; // Add your own styles
import Navbar from "../../Nav/Navbar";

const SubmittedPage = () => {
	return (
		<>
			<Navbar />
			<div className="submitted-container">
				<div className="submitted-card">
					<h2>Thank You!</h2>
					<p>Your message has been received. We will get back to you soon.</p>
					<Link to="/">Back to Homepage</Link>
				</div>
			</div>
		</>
	);
};

export default SubmittedPage;
