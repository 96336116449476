import React from "react";
import { Link as ScrollLink } from "react-scroll";

const SmoothScroll = () => {
	const linkStyle = {
		display: "block",
		height: 0,
		visibility: "hidden",
	};

	return (
		<div>
			{/* Link to trigger smooth scroll anywhere on the website */}
			<ScrollLink to="" smooth={true} duration={1000} style={linkStyle}>
				Scroll Smoothly
			</ScrollLink>

			{/* Your content here */}
		</div>
	);
};

export default SmoothScroll;
