import React, {useEffect} from "react";
import PropTypes from "prop-types";
import videoSrc from "./cal.mp4";
import AOS from "aos";
import "aos/dist/aos.css";
import "./component.css";

const PricingBanner = ({ title, subtitle }) => {
    useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<div className="service-page">
			<div className="banner">
				<div className="banner-overlay"></div>
				<video autoPlay loop muted playsInline className="cal-video">
					<source src={videoSrc} type="video/mp4" />
				</video>
				<div className="banner-text">
					<h1 data-aos="zoom-in-up">{title}</h1>
					{subtitle && (
						<h4 data-aos="zoom-in-up" className="subb-title" data-aos-delay="450">
							{subtitle}
						</h4>
					)}
				</div>
			</div>
		</div>
	);
};

PricingBanner.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
};

export default PricingBanner;
