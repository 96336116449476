import React from "react";
import PriceEstimator from "./Estimatorcal/webextimate";
import Navbar from "../../Nav/Navbar";
import { Helmet } from "react-helmet";
import PricingBanner from "./Components/PricingBanner";
import "./EstimatePage.css";

const EstimatePage = () => {

    return (
        <div className="app">
            <Helmet>
                <title>Wibuld Price Estimator | Get Your Web Design Quote</title>
                <meta
                    name="description"
                    content="Use Wibuld's Price Estimator to get an accurate quote for your web design project. Customize your options and see a detailed price range based on your selections. Ideal for businesses and individuals looking for tailored web solutions."
                />
                <meta
                    name="keywords"
                    content="Wibuld price estimator, web design quote, web development pricing, online price calculator, custom web solutions"
                />
            </Helmet>

            <Navbar />

            <PricingBanner
                title="Comprehensive Web Solutions Pricing"
                subtitle="Discover our complete range of services, from innovative design to ongoing management and optimization. "
            />

            <div className="Es-container">
                <div className="sticky">
                    <h3>Table of Contents</h3>
                    <ul>
                        <li><a href="#optimization">Website Optimization</a></li>
                        <li><a href="#support">Web Support Plans</a></li>
                        <li><a href="#estimator">Website Price Calculator</a></li>
                    </ul>
                </div>

                <div className="content">
                    <div id="optimization" className="content-section">
                        <h2>Optimize Your Website for Peak Performance</h2>
                        <p>
                            Our website optimization services are designed to enhance your site’s speed, search engine rankings, and user experience. We use advanced techniques to ensure your website loads quickly, functions smoothly, and ranks higher on search engines, driving more traffic to your business.
                        </p>
                        <div className="pricing-options">
                            <div className="option">
                                <h3>Basic Optimization Package</h3>
                                <p>$500 (one-time fee)</p>
                            </div>
                            <div className="option">
                                <h3>Advanced Optimization Package</h3>
                                <p>$900 (one-time fee)</p>
                            </div>
                            <div className="option">
                                <h3>Premium Optimization Package</h3>
                                <p>$1,500 (one-time fee, includes ongoing monitoring and adjustments for 3 months)</p>
                            </div>
                        </div>
                        <h3>Why You Need Website Optimization</h3>
                        <p>
                            A well-optimized website improves user experience, reduces bounce rates, and increases conversions. In the competitive online marketplace, even a slight improvement in load times can significantly boost your revenue. Our optimization services cover everything from image compression and caching strategies to advanced code minification and database tuning, ensuring your site performs at its best.
                        </p>
                    </div>

                    <div id="support" className="content-section">
                        <h2>Reliable Web Support for Ongoing Peace of Mind</h2>
                        <p>
                            Keep your website running smoothly with our monthly support plans. Whether you need regular updates, security checks, or emergency fixes, our team is here to ensure your website is always up and running.
                        </p>
                        <div className="pricing-options">
                            <div className="option">
                                <h3>Basic Support Plan</h3>
                                <p>$100/month (includes updates and minor fixes)</p>
                            </div>
                            <div className="option">
                                <h3>Standard Support Plan</h3>
                                <p>$200/month (includes security monitoring and updates)</p>
                            </div>
                            <div className="option">
                                <h3>Premium Support Plan</h3>
                                <p>$400/month (includes all features plus priority support)</p>
                            </div>
                        </div>
                        <h3>Comprehensive Web Support Breakdown</h3>
                        <p>
                            Our support plans are designed to keep your website secure, up-to-date, and performing optimally. Regular updates prevent potential vulnerabilities, while proactive security monitoring ensures your site remains protected from threats. With our Premium Support Plan, you'll receive priority assistance, ensuring any issues are resolved swiftly.
                        </p>
                        <ul className="supp">
                            <li><b>Updates & Maintenance:</b> Regular software updates to keep your site secure and functioning smoothly.</li>
                            <li><b>Security Monitoring:</b> Proactive monitoring to detect and respond to security threats.</li>
                            <li><b>Content Management:</b> Assistance with adding or updating content to keep your site fresh and relevant.</li>
                            <li><b>Emergency Support:</b> Quick response to any urgent issues, minimizing downtime and disruptions.</li>
                        </ul>
                    </div>

                    <div id="estimator" className="content-section">
                        <h2>Get an Instant Quote for Your Web Design Project</h2>
                        <p>
                            Use our Price Estimator to customize your web design project and receive an accurate quote instantly. Select your desired features, and we’ll provide a detailed price range that suits your needs and budget.
                        </p>
                        <PriceEstimator />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EstimatePage;
